<template>
  <gf-content title="Manage Payment Methods" subtitle="You can add payment methods for your payments.">
    <template #toolbar>
      <el-input style="width: 250px" class="mr-4" v-model="search"></el-input>
      <gf-button type="primary" @click="addPaymentMethod">
        <template slot="icon">
          <span class="svg-icon svg-icon-white svg-icon-sm">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
              </g>
            </svg>
          </span>
        </template>
        Add
      </gf-button>
    </template>
    <el-dialog title="Delete Payment Terms" :visible.sync="deleteModalVisible" append-to-body v-loading="deleteModalLoading" :close-on-click-modal="false">
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-danger svg-icon-xxl">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"/>
                <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3"/>
                <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1"/>
                <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1"/>
              </g>
            </svg>
          </span>
        </template>
        You are trying to delete {{ this.selected.length }} Payment Methods. This action cannot be undone. Are you sure?
      </gf-alert>
      <template #footer>
        <gf-button type="secondary" class="mr-2" @click="deleteModalVisible = false">
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                    <rect x="0" y="7" width="16" height="2" rx="1"/>
                    <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="danger" @click="remove">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                  <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          Delete
        </gf-button>
      </template>
    </el-dialog>
    <el-dialog :title="modalTitle" :visible.sync="paymentMethodModalVisible" append-to-body v-loading="paymentMethodModalLoading" :close-on-click-modal="false">
      <gf-form>
        <el-form label-width="100px" ref="paymentMethod" :model="paymentMethod" :rules="paymentMethodRules">
          <el-form-item label="Name" prop="name">
            <el-input placeholder="e.g. Cash" v-model="paymentMethod.name"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <gf-button type="secondary" @click="paymentMethodModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="primary" @click="save">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z" fill="#000000" fill-rule="nonzero"/>
                    <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="5" rx="0.5"/>
                  </g>
                </svg>
              </span>
            </template>
            Save
          </gf-button>
        </template>
      </gf-form>
    </el-dialog>
    <gf-table :data="paymentMethods" :selection-command="selectionCommand" @command="command" v-loading="loading" @row-click="readPaymentMethod">
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="Payment Method Name" prop="name">
      </el-table-column>
    </gf-table>
    <template #footer>
      <gf-pagination :pagination="pagination" @change="getPaymentMethods"></gf-pagination>
    </template>
  </gf-content>
</template>

<script>
import PaymentMethodService from '@/services/v1/PaymentMethod'
import { debounce } from 'lodash'

export default {
  props: {
    active: Number
  },
  data () {
    return {
      search: '',
      filter: {},
      paymentMethods: [],
      selected: [],

      mode: '',
      modalTitle: '',
      loading: false,
      paymentMethodModalVisible: false,
      paymentMethodModalLoading: false,
      deleteModalVisible: false,
      deleteModalLoading: false,

      selectionCommand: [
        { label: 'Delete', command: 'delete' }
      ],

      paymentMethod: {
        name: null
      },
      paymentMethodRules: {
        name: [{
          required: true, message: 'Name is require', trigger: 'blur'
        }]
      },

      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      this.getPaymentMethods()
    }, 400)
  },
  methods: {
    addPaymentMethod () {
      this.paymentMethod = {
        name: null
      }
      this.mode = 'add'
      this.modalTitle = 'Create New Payment Method'
      this.paymentMethodModalVisible = true
    },
    readPaymentMethod (row) {
      this.mode = 'update'
      this.modalTitle = 'Update Payment Method'
      this.paymentMethod = this.$_.cloneDeep(row)
      this.paymentMethodModalVisible = true
    },
    async remove () {
      try {
        this.deleteModalLoading = true
        const paymentMethodsId = []
        for (let i = 0; i < this.selected.length; i++) {
          const sl = this.selected[i]
          paymentMethodsId.push(sl.id)
        }

        const pmService = new PaymentMethodService()
        await pmService.delMultiple(paymentMethodsId)
        this.$message.success(`${paymentMethodsId.length} Payment Methods were successfully deleted`)

        this.deleteModalVisible = false
        this.getPaymentMethods()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.deleteModalLoading = false
      }
    },
    save () {
      this.$refs.paymentMethod.validate()
        .then(async () => {
          try {
            this.paymentMethodModalLoading = true

            const paymentMethod = this.$_.cloneDeep(this.paymentMethod)
            this.$Sanitize(paymentMethod)

            if (this.mode === 'add') {
              const pmService = new PaymentMethodService()
              await pmService.create(paymentMethod)
              this.$message.success(`${this.paymentMethod.name} successfully added`)
            } else {
              const pmService = new PaymentMethodService(paymentMethod.id)
              await pmService.update(paymentMethod)
              this.$message.success(`${this.paymentMethod.name} was successfully updated`)
            }

            this.paymentMethodModalVisible = false
            this.getPaymentMethods()
          } catch (error) {
            this.$Error(error)
          } finally {
            this.paymentMethodModalLoading = false
          }
        })
        .catch(() => {})
    },
    command (event) {
      this.selected = event.selection
      if (event.cmd === 'delete') {
        this.deleteModalVisible = true
      }
    },

    async getPaymentMethods () {
      try {
        this.loading = true
        const pmService = new PaymentMethodService()
        const response = await pmService.list(this.pagination.page, null, this.filter)
        this.paymentMethods = response.data.rows

        if (response.data.count === 0 && response.data.rows.length !== 0) {
        } else this.pagination.total = response.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$emit('update:active', 2)
    this.getPaymentMethods()
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Settings' },
      { title: 'Configurations' },
      { title: 'Payment Methods' }
    ])
  }
}
</script>
